
  import { Vue, Component } from "vue-property-decorator";
  import dayjs from "dayjs";
  import { getUser , queryUser ,changeUser,deleteMachineNum ,deleteUser,changeRole} from "@/api/modules/getmachine";
  @Component({
    name: "Eqmanagement",
  })
  export default class Eqmanagement extends Vue {
    private tableData: Array<any> = [];
    private pageNo = 1;
    private pageSize = 10;
    private form = {search:""};
    private id = "";
   private dialogFormVisible =false;
   private form2 = {role:""};
    
  
    private async mounted() {
    //   let datas = [];
    //   for (let i = 0; i < 400; i++) {
    //     datas.push({
    //       date: dayjs().format("YYYY-MM-DD"),
    //       name: `姓名${i}`,
    //       address: `地址${i}`,
    //     });
    //   }
    //   this.tableData = datas;
      let res =await getUser(1)
      this.tableData = res.list;
      this.pageSize = res.total
      //this.pageNo = res.page_count
      console.log("默认第一页",res)
    }
  
   
  
    private handleCurrentChange(val: number) {
      console.log(`当前页: ${val}`);
      this.pageNo = val;
    }
  
    //修改密码
    private async openQrcode(id:string){
      
      
        this.$prompt('请输入密码', '修改密码', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^[a-zA-Z]\w{5,17}$/,
          inputErrorMessage: '密码格式不正确。字母开头，长度在6~18之间，只能包含字母、数字和下划线。'
          
        }).then(async(value:any) => {
          let cc ={id:id,password:value.value}
          console.log(cc)
          let res = await changeUser(cc)
          if(res){
          this.$message({
            type: 'success',
            message: '密码修改成功！' 
          });
        }else{
          this.$message({
            type: 'error',
            message: '密码修改失败！请联系后台管理人员。' 
          });
        }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          });       
        });
      
    }
    //打开修改权限页面
    private async change(id:string){
      this.id = id
      this.dialogFormVisible = true
      console.log(this.id)
  }
  private async submit(){
    console.log(this.form2)
    let param = {
      user:this.id,
      role:this.form2.role
    }
    
    await changeRole(param)
      this.dialogFormVisible = false
      this.id = ""
      let res =await getUser(1)
        this.tableData = res.list;
        this.pageSize = res.total
  }
    private  open(id:string) {
        this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          console.log("id",id)
          let cc ={ id: id}
        let res1 = await deleteUser(cc)
      
        if(res1){
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }else{
          this.$message({
            type: 'info',
            message: '删除失败请联系后台！'
          });  
          return   
        }
        //刷新第一页
        let res =await getUser(1)
        this.tableData = res.list;
        this.pageSize = res.total
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      }
    

    private async onSubmit() {
      console.log(this.form);
      let res =await queryUser(this.form.search.match(/^[ ]*$/)?"":(this.form.search));
      console.log(res);
      this.tableData = res.list;
      this.pageSize = res.total
    }
  }
  